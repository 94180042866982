<template>
  <section class="viviendas-ver ml-4">
   <div class="row my-4">
        <div class="col-12" >
            <el-breadcrumb separator-class="el-icon-arrow-right" >
                <el-breadcrumb-item :to="{ name: 'viviendas.sistema' }" >Viviendas</el-breadcrumb-item >
                <el-breadcrumb-item v-loading="loading">{{vivienda.nombre_full}}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
    </div>
    <tabs :tabs="arrayTabs"></tabs>
    <router-view class="mt-4"></router-view>
    
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import Viviendas from '~/services/viviendas'
export default {
  components:{
    // modalRegistrarPago: () => import('./partials/modalRegistrarPago')
  },
    data(){
      return{
        inputSearch:'',
        arrayTabs: [
            {
                titulo:'Pagos de administación',
                ruta:"viviendas.ver.pagos",
                num: 0
            },
            {
                titulo:'Residentes',
                ruta:"viviendas.ver.residentes",
            },
        ],
        vivienda:{},
        loading:false,
      }
    },
    computed: {
      ...mapGetters({
        indicador: 'viviendas/getNumMesesDebe'
      })
    },
    created(){
      this.obtener_vivienda()
    },
    watch: {
      indicador(){
        if(this.indicador >= 0) this.arrayTabs[0].num = this.indicador
      }
    },
    methods:{
      async obtener_vivienda(){
        try {    
          this.loading = true
          const {data} = await Viviendas.mostrar(this.$route.params.id_vivienda)
          this.vivienda = data.data
          // indicador de debe
          let indicador = data.data.num_meses_debe
           
          this.$store.dispatch('viviendas/actualizarNumMesesDebe',indicador) 

          if(indicador >= 0) this.arrayTabs[0].num = this.indicador

          this.loading = false

        } catch (e){
            this.errorCatch(e)
            this.loading = false
        }
      },
    }
}
</script>

<style lang="scss" scoped>
    
</style>