import { render, staticRenderFns } from "./ver.vue?vue&type=template&id=034ecd16&scoped=true&"
import script from "./ver.vue?vue&type=script&lang=js&"
export * from "./ver.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "034ecd16",
  null
  
)

export default component.exports